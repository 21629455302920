<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()"/>
      <span>Banklot</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <ul class="layout-topbar-menu hidden lg:flex origin-top">

      <button class="p-link layout-topbar-button"
              type="button"
              @click="changeTheme($event)">
        <i class="pi pi-circle-fill"></i>
      </button>

      <Button class="p-link layout-topbar-button"
              icon="pi pi-eye" @click="toggleCurrency"
      >{{ getCurrency() }}
      </Button>

      <OverlayPanel ref="op" :dismissable="true" class="currencyPanel">
        <DataTable :value="currencies"
                   v-model:selection="selectedCurrency"
                   selectionMode="single"
                   dataKey="id"
                   @row-select="onRowSelect"
                   responsiveLayout="scroll">

          <Column field="code" headerStyle="display: none">
            <template #body="{data}">
              <div v-if="data.code==='VEN'" class="countryDrop">
                <div></div>
                <img src="images/VEN.png" class="flag" alt=""/>
              </div>
              <div v-if="data.code==='USD'" class="countryDrop">
                <img src="images/USD.png" class="flag" alt=""/>
              </div>
            </template>
          </Column>
          <Column field="code" headerStyle="display: none"/>
        </DataTable>
      </OverlayPanel>

      <li>
        <button class="p-link layout-topbar-button" @click="toggle">
          <i class="pi pi-user"></i>
          <Menu id="overlay_menu" ref="menu" :model="items" :popup="true"/>
        </button>
      </li>

    </ul>
  </div>

  <Profile
      :show="displayProfile"
      @close="closeProfileDialog"/>
  />

</template>

<script>


import EventBus from "@/app/AppEventBus";
import VueCookies from 'vue-cookies'
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import Profile from "@/components/Settings/Users/Dialog/Profile";

export default {
  emits: ["menu-toggle"],
  mixins: [generalMixin],
  components: {
    'Profile': Profile,
  },
  data() {
    return {
      items: [
        {
          label: 'Usuario',
          items: [
            {
              label: 'Detalles',
              icon: 'pi pi-user',
              command: () => {
                this.displayProfile = true;
              }
            },
            {
              label: 'Cerrar sesión',
              icon: 'pi pi-upload',
              command: () => {
                localStorage.removeItem('token');
                localStorage.removeItem('vuex');
                this.$store.replaceState({});
                window.location.hash = "/login"
              }
            }
          ]
        }
      ],
      displayProfile: false,
      currencies: [
        {id: 1, code: 'VEN'},
        {id: 2, code: 'USD'},
      ],
      selectedCurrency: null,
    }
  },
  created() {
    this.mainService = new MainService;
  },
  async mounted() {

  },
  methods: {
    changeTheme(event) {
      if (!VueCookies.get('dark')) {
        VueCookies.set('dark', true)
      } else {
        VueCookies.remove('dark')
      }

      const theme = !VueCookies.get('dark') ? 'bootstrap4-light-green' : 'bootstrap4-dark-green'
      EventBus.emit('theme-change', {theme: theme, dark: null});
      event.preventDefault();
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    // onTopbarMenuToggle(event) {
    //   this.$emit('topbar-menu-toggle', event);
    // },
    topbarImage() {
      return this.$appState.darkTheme ? 'images/logo.png' : 'images/logo.png';
    },
    closeProfileDialog() {
      this.displayProfile = false;
    },
    getCurrency() {
      return this.$store.getters.getCurrency;
    },
    toggleCurrency(event) {
      this.$refs.op.toggle(event);
    },
    onRowSelect(event) {
      this.$store.commit('setCurrency', event.data.code);
      VueCookies.set('currency', event.data.code);
      localStorage.setItem('currency', event.data.code);
      window.location.href = '/'
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  }
}
</script>
<style>
.currency-button {
  background-color: unset;
  border: solid 0;
  color: black;
  font-size: large;
}

.flag {
  width: 25px;

}

.countryDrop {
  display: flex;
  justify-content: space-between;
}

.currencyPanel {
  padding: unset;
}

.ui-datatable table thead tr {
  display: none;
}
</style>